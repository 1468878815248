<template>
  <v-dialog
    v-if="form !== null"
    overlay-color="primary"
    overlay-opacity=".97"
    v-model="bActive"
    max-width="80%"
    persistent>
    <v-card
      style="padding: 20px">
      <v-container>
        <v-layout
          row
          justify-center>
          <p
            style="font-size: 22px !important; font-weight: normal !important"
            class="section_titles"
            v-text="form.name"
          />
        </v-layout>
        <v-container>
          <v-layout
            column
            align-center>
            <p
              style="font-size: 15px !important; font-weight: normal !important; margin: 15ox"
              class="section_titles"
              v-text="form.email"
            />
            <p
              style="font-size: 15px !important; font-weight: normal !important; margin: 15ox"
              class="section_titles"
              v-text="form.phone"
            />
            <p
              style="font-size: 16px !important; font-weight: normal !important; margin: 15ox"
              class="section_titles"
              v-text="form.message"
            />
          </v-layout>
        </v-container>
      </v-container>
        <v-layout
          row
          justify-center>
          <v-layout
            row
            justify-center
            style="max-width: 50%">
            <v-btn
              @click="close"
              large
              dark
              color="primary">
              Cerrar
            </v-btn>
          </v-layout>
        </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    form: Object,
    bActive: Boolean
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
  
</style>